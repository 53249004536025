/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
    bool, func, object, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getPriceArrays } from '../../../../../../../../helpers/DynamicPricing/getPriceArrays';
import PriceRange from './PriceRange';
import { getFeatureFlags } from '../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',

    },
    wrapperLeft: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '16px',
        '& $dialog': {
            left: 'unset',
            right: '16px',
            '@media screen and (max-width: 440px)': {
                width: '100%',
            },
        },
    },
    dialog: {
        display: 'flex',
        justifyContent: 'center',
        width: '398px',
        '@media screen and (max-width: 1024px)': {
            padding: '0px 10px 0px 0px',
        },
        '@media screen and (max-width: 400px)': {
            width: '100%',
            padding: '0px 5px 0px 15px',
        },
    },
    dialogOpen: {
        opacity: '1',
        visibility: 'visible',
    },
    dialogClose: {
        opacity: '0',
        visibility: 'hidden',
    },
    dialogWrapper: {
        background: 'white',
        borderRadius: '5px',
        boxShadow: '0px 4px 8px 0px #0000004D',
        margin: '0 auto',
        paddingBottom: '4px',
        minHeight: '250px',
        height: 'max-content',
    },
    dialogHeader: {
        justifyContent: 'space-between',
        width: '100%',
        gridTemplateColumns: 'calc(100% - 16px) 16px',
        display: 'grid',
        padding: '9px 12.5px 9px 20px',
        backgroundColor: '#E9E9E9',
        alignItems: 'center',
        borderRadius: '5px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    dialogContent: {
        padding: '16px 20px 12px',
        gridTemplateColumns: '50%  auto',
        display: 'grid',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& div': {
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '0.5px',
            '@media screen and (max-width: 400px)': {
                fontSize: '14px',
            },
            '@media screen and (max-width: 362px)': {
                fontSize: '12px',
            },
        },
    },
    title: {
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '0.5px',
        fontFamily: 'LatoBold',
        width: 'auto',
    },
    dialogMiddle: {
        borderTop: '1px solid #C4C4C4',
        margin: '0px 20px 12px',
        paddingTop: '12px',
        gridTemplateColumns: '50%  auto',
        display: 'grid',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'auto',
        '& div': {
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '0.5px',
            fontFamily: 'Area Variable, LatoBold',
            fontWeight: '700',
            '@media screen and (max-width: 400px)': {
                fontSize: '14px',
            },
            '@media screen and (max-width: 362px)': {
                fontSize: '12px',
            },
        },
    },
    dialogBottom: {
        fontSize: '14px',
        borderTop: '1px solid #C4C4C4',
        lineHeight: '20px',
        letterSpacing: '0.5px',
        margin: '0px 20px 12px',
        display: 'flex',
        width: 'auto',
        paddingTop: '12px',
    },
    priceContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'end',
    },
    priceContainer: {
        display: 'inline-block',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#555555',
        '@media screen and (max-width: 600px)': {
            fontSize: '12px',
            lineHeight: '16px',
        },
        '& span': {
            display: 'inline-block',
        },
    },
    serviceFee: {
        marginLeft: '2px',
    },
    serviceFeeContainer: {
        display: 'inline-block',
    },
    priceAddOnContainer: {
        '& span': {
            fontSize: '16px',
            color: '#434343 !important',
        },
    },
}));

const PriceBreakdownDetail = ({
    priceMessaging, productDeliveryType, dynamicPriceData, priceRangeLayout, openPriceDialog, showBreakDownPrice, addOnPrice,
}) => {
    const classes = useStyles();
    const featureFlags = useSelector(getFeatureFlags);
    const whichCollectionPageRestructure = featureFlags['which-collection-page-restructure'];
    const collectionRestructureVariantB = whichCollectionPageRestructure?.toLowerCase() === 'variantb';
    const getDetails = () => priceMessaging?.[productDeliveryType.toLowerCase()] || {};

    const summaryModal = getDetails().summary_modal || {};

    const getFieldLabel = (key = '') => {
        const labelsSection = getDetails()?.summary_modal?.summary_labels || [];
        const findLabel = (labelsSection || []).find((item) => item.label_key.toLowerCase() === key.toLowerCase()) || null;
        if (findLabel) {
            return findLabel?.label_override;
        }
        return key; // return what we get from service
    };
    const getPrice = (type = 'total', serviceType = '') => {
        if (type === 'total') {
            // to avoid updating reference
            const totalPrices = [];
            dynamicPriceData?.prices.forEach((item) => {
                if (item.type === 'retail') {
                    totalPrices.push({
                        type: item.type,
                        value: item.total,
                    });
                } else {
                    totalPrices.push({
                        type: item.type,
                        value: item.total,
                    });
                }
            });
            return getPriceArrays(totalPrices);
        }
        if (type === 'item_price') {
            return getPriceArrays(dynamicPriceData?.prices);
        }
        if (type === 'charges') {
            const chargesPrices = [];
            dynamicPriceData?.prices.forEach((item) => {
                (item?.charges || []).forEach((charge) => {
                    if (charge?.label?.toLowerCase() === serviceType?.toLowerCase()) {
                        if (item.type === 'retail') {
                            chargesPrices.push({
                                charges: item.charges,
                                type: item.type,
                                value: item.charges[0].amount,
                            });
                        } else {
                            chargesPrices.push({
                                charges: item.charges,
                                type: item.type,
                                value: item.charges[0].amount,
                            });
                        }
                    }
                });
            });
            return getPriceArrays(chargesPrices);
        }
        return {};
    };

    const closeIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52925 4.25788C3.7896 3.99753 4.21171 3.99753 4.47206 4.25788L8.00065 7.78648L11.5292 4.25788C11.7896 3.99753 12.2117 3.99753 12.4721 4.25788C12.7324 4.51823 12.7324 4.94034 12.4721 5.20069L8.94346 8.72929L12.4721 12.2579C12.7324 12.5182 12.7324 12.9403 12.4721 13.2007C12.2117 13.461 11.7896 13.461 11.5292 13.2007L8.00065 9.6721L4.47206 13.2007C4.21171 13.461 3.7896 13.461 3.52925 13.2007C3.2689 12.9403 3.2689 12.5182 3.52925 12.2579L7.05784 8.72929L3.52925 5.20069C3.2689 4.94034 3.2689 4.51823 3.52925 4.25788Z" fill="black" />
        </svg>
    );

    const isInDynamicResponse = (name = '') => (dynamicPriceData?.prices?.[0]?.charges || []).filter((item) => item.label.toLowerCase() === name.toLowerCase());

    if (!whichCollectionPageRestructure) {
        return <></>;
    }

    const priceDetails = () => {
        let itemPrice = null;
        let serviceCharges = null;
        let valueOverride = '';
        (summaryModal?.summary_labels || []).forEach((item) => {
            if (item.label_key === 'item_price') {
                itemPrice = getPrice('item_price', item.label_key);
            }
            if (item.label_key === 'service fee') {
                serviceCharges = getPrice('charges', item.label_key);
            }
            if (item.value_override) {
                valueOverride = 'plus shipping';
            }
        });

        if (productDeliveryType === 'fpt' && (itemPrice || serviceCharges)) {
            return (
                <span className={addOnPrice ? `${classes.priceAddOnContainer} ${classes.priceContainer}` : classes.priceContainer}>
                    {serviceCharges && itemPrice && (
                        <div className={classes.serviceFeeContainer}>
                            <span className={classes.underline}>
                                {addOnPrice && <span>(</span>}
                                <PriceRange
                                    priceRangeLayout={priceRangeLayout}
                                    skuPriceRange={getPrice('total')}
                                    collectionRestructureEnabled={collectionRestructureVariantB}
                                    forceItemPriceOnly
                                    displayPrice
                                    noFromText
                                    showSinglePrice
                                />
                                <span className={classes.serviceFee}>with service fee</span>
                                {addOnPrice && <span>)</span>}
                            </span>
                        </div>
                    )}
                </span>
            );
        }
        if (valueOverride && (productDeliveryType?.toLowerCase() === 'standard' || productDeliveryType?.toLowerCase() === 'gpt')) {
            return (
                <span className={classes.priceContainer}>{valueOverride}</span>
            );
        }

        return null;
    };

    return (
        showBreakDownPrice ? (
            priceDetails()
        ) : (
            <div className={classes.dialog}>
                <div className={classes.dialogWrapper}>
                    <div className={classes.dialogHeader}>
                        <div className={classes.title}>
                            {summaryModal?.header_copy || 'Price Breakdown'}
                        </div>
                        <div role="presentation" className={classes.closeIcon} aria-label="close" onClick={() => openPriceDialog()}>
                            {closeIcon()}
                        </div>
                    </div>
                    <div className={classes.dialogContent}>
                        {(summaryModal?.summary_labels || []).map((item) => {
                            if (
                                isInDynamicResponse(item.label_key).length > 0
                            || item.label_key === 'item_price'
                            || item.value_override
                            ) {
                                return (
                                    <>
                                        <div>{getFieldLabel(item.label_key)}</div>
                                        <div className={classes.priceContent}>
                                            {item.value_override ? item.value_override : (
                                                <PriceRange
                                                    priceRangeLayout={priceRangeLayout}
                                                    skuPriceRange={getPrice(item.label_key === 'item_price' ? 'item_price' : 'charges', item.label_key)}
                                                    collectionRestructureEnabled={collectionRestructureVariantB}
                                                    forceItemPriceOnly
                                                    displayPrice
                                                    noFromText
                                                    priceBreakDownItem
                                                />
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            return (<></>);
                        })}
                    </div>
                    <div className={classes.dialogMiddle}>
                        <div>
                            {summaryModal?.total_label}
                        </div>
                        <div className={classes.priceContent}>
                            <PriceRange
                                priceRangeLayout={priceRangeLayout}
                                skuPriceRange={getPrice('total')}
                                displayPrice
                                sisterBrandProduct={false}
                                collectionRestructureEnabled={collectionRestructureVariantB}
                                noFromText
                            />
                        </div>
                    </div>
                    <div className={classes.dialogBottom}>
                        {summaryModal?.muted_copy}
                    </div>
                </div>
            </div>
        )
    );
};

PriceBreakdownDetail.propTypes = {
    dynamicPriceData: object.isRequired,
    productDeliveryType: string.isRequired,
    priceRangeLayout: object.isRequired,
    openPriceDialog: func.isRequired,
    priceMessaging: object.isRequired,
    showBreakDownPrice: bool,
    addOnPrice: bool,
};
PriceBreakdownDetail.defaultProps = {
    showBreakDownPrice: false,
    addOnPrice: false,
};

export default PriceBreakdownDetail;
